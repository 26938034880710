import Image from '../../Image'
// import { Toaster, toast } from "react-hot-toast";

export default function Answer({ answer }: { answer: string }) {
  return (
    <div className="bg-white rounded-lg p-8 border shadow-lg border-gray-100">
    <h2 className="text-md flex items-center font-bold uppercase tracking-wider mb-4 text-gray-500">Answer:
      {answer && <>
        <span className="flex items-center scale-90 hover:scale-105 transition-all w-fit ml-auto">
      <button
                onClick={() => {
                  navigator.clipboard.writeText(answer.trim());
                }}
              >
                <Image
                  unoptimized
                  src="/img/copy.svg"
                  alt="footer"
                  width={20}
                  height={20}
                  className="cursor-pointer"
                />
              </button>
              </span>
              </>}
       </h2>
    {answer ? (<>
                <p className="text-md leading-relaxed text-gray-700 text-justify"> {answer.trim()}</p>
              </>   ) : (
              <div className="flex w-full flex-col gap-2">
                <div className="h-6 w-full animate-pulse rounded-md bg-blue-200" />
                <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
                <div className="h-6 w-full animate-pulse rounded-md bg-blue-200" />
                <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
              </div>
            )}
    {/* <p className="text-lg leading-relaxed text-gray-700">
      You can contribute computing resources to the network by becoming a provider on a peer-to-peer platform like the Golem Network. To do this, you need to run a provider agent on your computer, which adheres to the Golem network protocol and transforms your machine into a Golem node.
    </p>
    <p className="mt-4 text-lg leading-relaxed text-gray-700">
      This allows you to offer your idle computational resources to requestors in need. In return for sharing your processing power, you will receive compensation in the form of GLM, creating a decentralized marketplace for computational power.
    </p> */}
  </div>
    // <div className="container flex h-auto w-full shrink-0 gap-4 rounded-lg border border-solid border-[#989abe] bg-white p-5 lg:p-10">
      
    //   <div className="w-full">
    //     <div className="flex items-center justify-between pb-3">
    //       <div className="flex gap-4">
    //         <Image
    //           unoptimized
    //           src="/img/Info.svg"
    //           alt="footer"
    //           width={24}
    //           height={24}
    //           className="block lg:hidden"
    //         />
    //         <h3 className="text-base font-bold uppercase text-black">
    //           Answer:{" "}
    //         </h3>
    //       </div>
    //       {answer && (
    //         <div className="flex items-center gap-3 scale-90 hover:scale-105 transition-all">
    //           <button
    //             onClick={() => {
    //               navigator.clipboard.writeText(answer.trim());
    //               // toast("Answer copied to clipboard", {
    //               //   icon: "✂️",
    //               // });
    //             }}
    //           >
    //             <Image
    //               unoptimized
    //               src="/img/copy.svg"
    //               alt="footer"
    //               width={20}
    //               height={20}
    //               className="cursor-pointer"
    //             />
    //           </button>
    //         </div>
    //       )}
    //     </div>
    //     <div className="flex flex-wrap content-center items-center gap-[15px]">
    //       <div className="w-full whitespace-pre-wrap text-base font-light leading-[152.5%] text-black">
    //         {answer ? (
    //           answer.trim()
    //         ) : (
    //           <div className="flex w-full flex-col gap-2">
    //             <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
    //             <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
    //             <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
    //             <div className="h-6 w-full animate-pulse rounded-md bg-gray-300" />
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   {/* <Toaster
    //     position="top-center"
    //     reverseOrder={false}
    //     toastOptions={{ duration: 2000 }}
    //   /> */}
    // </div>
  );
}
