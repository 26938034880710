import { ArrowRight, ChevronRight } from "lucide-react";
import Image from "../../Image"

const SimilarTopics = ({
  similarQuestions,
  handleDisplayResult,
  reset,
}: {
  similarQuestions: string[];
  handleDisplayResult: (item: string) => void;
  reset: () => void;
}) => {
  return (
    // <div className="container flex h-auto w-full md:w-fit shrink-0 gap-4 rounded-lg border border-solid border-[#C2C2C2] bg-white p-5 lg:p-10">
    <div className="bg-gray-50 w-full rounded-lg p-6">
        <h2 className="text-md font-bold uppercase tracking-wider mb-4 text-gray-500">Related Questions:</h2>
      <div className="flex-1 divide-y divide-[#E5E5E5]">
        <div className="  space-y-[15px] divide-y divide-[#E5E5E5]">
          {similarQuestions.length > 0 ? (
            similarQuestions.map((item) => (
              <button
              className="w-full p-3 text-left bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors group"
              key={item}
                onClick={() => {
                  reset();
                  handleDisplayResult(item);
                }}
              >
                 <div className="flex items-center justify-between">
                        <span className="text-gray-700 group-hover:text-gray-900">{item}</span>
                        <ArrowRight className="w-4 h-4 text-gray-400 group-hover:text-gray-600" />
                      </div>
                
                
                {/* <p               
                className="bg-white p-1  text-left rounded-md shadow-sm hover:shadow-md transition-shadow cursor-pointer flex items-center justify-between">
                  {item}
                </p>
                <ChevronRight className="text-gray-400 " /> */}
              </button>
            ))
          ) : (
            <>
              <div className="h-10 w-full animate-pulse rounded-md bg-gray-300" />
              <div className="h-10 w-full animate-pulse rounded-md bg-gray-300" />
              <div className="h-10 w-full animate-pulse rounded-md bg-gray-300" />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SimilarTopics;
