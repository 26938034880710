import { Link2 } from 'lucide-react';
import React, { useState } from 'react';

interface ShareLinkProps {
    title:string
}

const ShareLink:React.FC<ShareLinkProps> = ({ title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);

  const [url,setUrl] = useState(window.location.href+`?search=`+encodeURIComponent(title))

  // Social media share URLs
  const shareUrls = {
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' ' + url)}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`,
    email: `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(url)}`
  };

  // Copy link to clipboard
  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    });
  };

  // Open share in new window
  const openShareWindow = (shareUrl:string) => {
    window.open(shareUrl, '_blank', 'width=600,height=400');
  };

  return (
    <div className="relative">
      {/* Share Button */}
      <button 
              onClick={() => setIsOpen(!isOpen)}
        className="w-full p-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors flex items-center gap-2">
            <Link2 className="w-4 h-4" />
                    Share Results
     </button>
      {/* <button 
        onClick={() => setIsOpen(!isOpen)}
        className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full flex items-center"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"/>
          <polyline points="16 6 12 2 8 6"/>
          <line x1="12" x2="12" y1="2" y2="15"/>
        </svg>
        Share
      </button> */}

      {/* Popup */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-96 relative ">
            {/* Close Button */}
            <button 
              onClick={() => setIsOpen(false)}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"/>
                <line x1="6" y1="6" x2="18" y2="18"/>
              </svg>
            </button>

            <h2 className="text-xl font-bold mb-4 text-center">Share this link</h2>

            {/* Copy Link Section */}
            <div className="flex mb-4">
              <input 
                type="text" 
                value={url} 
                readOnly 
                className="flex-grow p-2 border rounded-l-lg text-black"
              />
              <button 
                onClick={handleCopyLink}
                className={`p-2 border rounded-r-lg bg-gray-400 ${copied ? 'bg-green-500 text-white' : 'bg-gray-100'}`}
              >
                {copied ? 'Copied!' : (
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
                  </svg>
                )}
              </button>
            </div>

            {/* Social Share Buttons */}
            <div className="grid grid-cols-3 gap-4 ">
              {/* Facebook */}
              <button 
                onClick={() => openShareWindow(shareUrls.facebook)}
                className="flex flex-col items-center hover:bg-gray-100 p-2 rounded-lg"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#1877F2">
                  <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"/>
                </svg>
                <span className="text-xs mt-1 text-gray-700">Facebook</span>
              </button>

              {/* WhatsApp */}
              <button 
                onClick={() => openShareWindow(shareUrls.whatsapp)}
                className="flex flex-col items-center hover:bg-gray-100 px-2 pt-3  rounded-lg "
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="34" viewBox="0 0 24 24" fill="#25D366">
                  <path d="M.057 22l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.149-.174.198-.297.297-.495.099-.198.05-.372-.025-.521-.075-.149-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.041 1.016-1.041 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/>
                </svg>
                <span className="text-xs mt-1 text-gray-700">WhatsApp</span>
              </button>

              {/* Twitter/X */}
              <button 
                onClick={() => openShareWindow(shareUrls.twitter)}
                className="flex flex-col items-center hover:bg-gray-100 p-2 rounded-lg"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#000000">
                  <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"/>
                </svg>
                <span className="text-xs mt-1 text-gray-700">Twitter/X</span>
              </button>

              {/* LinkedIn */}
              <button 
                onClick={() => openShareWindow(shareUrls.linkedin)}
                className="flex flex-col items-center hover:bg-gray-100 p-2 rounded-lg"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#0A66C2">
                  <path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/>
                </svg>
                <span className="text-xs mt-1 text-gray-700">LinkedIn</span>
              </button>

              {/* Email */}
              <button 
                onClick={() => openShareWindow(shareUrls.email)}
                className="flex flex-col items-center hover:bg-gray-100 p-2 rounded-lg"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="#EA4335">
                  <path d="M22 5.892l-11.995 6.521-12.005-6.494v-2.919h24v2.892zm-24-2.202v15.31h24v-15.31l-11.995 6.521-12.005-6.521z"/>
                </svg>
                <span className="text-xs mt-1 text-gray-700">Email</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareLink;