import { Timer } from "lucide-react";
import Image from "../../Image"
import SourceCard from "./SourceCard";

export default function Sources({
  sources,
}: {
  sources: { name: string; url: string,thumbnailUrl:string,snippet:string, }[] | any[];
}) {
  return (
    <div className="bg-gray-50 rounded-lg py-6 px-6">
      
        <h2 className="text-md font-bold uppercase tracking-wider mb-4 text-gray-500">Referenced Sources:</h2>

     
      <div className="flex w-full flex-wrap content-center items-center gap-[10px]">
        {sources.length > 0 ? (
          sources.slice(0,6).map((source,idx) => {
            return <a
            key={idx}
            href={source.url}
            target="_blank"
            rel="noopener noreferrer"
            className="group bg-white rounded-lg overflow-hidden hover:shadow-lg transition-all duration-200"
          >
            <div className="flex">
              <div className="w-48 h-32 flex-shrink-0 overflow-hidden">
                <img
                src={`${source.thumbnailUrl}`}
                  // src={`https://www.google.com/s2/favicons?domain=${source.url}&sz=128`}
                  alt={source.siteName}
                  className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-200"
                />
              </div>
              <div className="flex-1 p-4">
                <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
                  <span>{source.siteName}</span>
                  <span>•</span>
                  <span className="flex items-center">
                    <Timer className="w-4 h-4 mr-1" />
                    {source.datePublishedDisplayText}
                  </span>
                </div>
                <h3 className="font-medium text-gray-900 group-hover:text-blue-600 mb-2">
                  {source.name.split('-')[0]}
                </h3>
                <p className="text-sm text-gray-600">{source.snippet}</p>
              </div>
            </div>
          </a>
            // <SourceCard source={sources[i]} key={sources[i].url} />
})
        ) : (
          <>
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
            <div className="h-20 w-[230px] max-w-sm animate-pulse rounded-md bg-gray-300" />
          </>
        )}
      </div>
    </div>
  );
}
