import Image from "../../Image";
import { FC, useState } from "react";
import { Send, Zap, Sparkles } from "lucide-react";

type TInputAreaProps = {
  promptValue: string;
  setPromptValue: React.Dispatch<React.SetStateAction<string>>;
  handleDisplayResult: () => void;
  disabled?: boolean;
  reset?: () => void;
  maxLength?: number;
};

const InputArea: FC<TInputAreaProps> = ({
  promptValue,
  setPromptValue,
  handleDisplayResult,
  disabled = false,
  reset,
  maxLength = 500
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (promptValue.trim().length === 0) return;
    if (reset) reset();
    handleDisplayResult();
  };

  return (
    <div className="max-w-2xl mx-auto px-4">
      <form 
        onSubmit={handleSubmit}
        className="
          relative 
          bg-white 
          rounded-2xl 
          shadow-[0_10px_40px_-10px_rgba(0,0,0,0.1)] 
          overflow-hidden 
          border 
          border-gray-400
          transition-all 
          duration-300
          hover:shadow-[0_15px_50px_-15px_rgba(0,0,0,0.15)]
        "
      >
        <div className="flex items-center px-4 py-1">
          <Sparkles 
            className={`
              mr-4 
              text-blue-500 
              transition-all 
              duration-300
              ${isFocused ? 'rotate-12 scale-110' : ''}
            `} 
            size={24} 
          />
          
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="What can I help you with?"
              value={promptValue}
              onChange={(e) => {
                if (e.target.value.length <= maxLength) {
                  setPromptValue(e.target.value);
                }
              }}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              disabled={disabled}
              required
              className="
                w-full 
                pr-16
                text-lg 
                text-gray-800 
                bg-transparent 
                border-none 
                focus:outline-none 
                placeholder-gray-400
                rounded-sm
                transition-all 
                duration-300
              "
            />
            {promptValue && (
              <span className="absolute right-0 top-1/2 -translate-y-1/2 text-xs text-gray-400">
                {promptValue.length}/{maxLength}
              </span>
            )}
          </div>
          
          <button
            type="submit"
            disabled={disabled || promptValue.trim().length === 0}
            className="
              ml-4 
              group
              bg-gradient-to-br 
              from-blue-500 
              to-indigo-600 
              text-white 
              rounded-lg 
              w-14 
              h-14
              flex 
              items-center 
              justify-center
              hover:scale-105 
              transition-transform 
              duration-300
              disabled:opacity-50
              relative
              overflow-hidden
            "
          >
            {disabled ? (
              <TypeAnimation className="animate-pulse" />
            ) : (
              <Send 
                size={30} 
                className=" text-indigo-600 border-1 border-indigo-600 rounded-full p-1
                  group-hover:scale-120 
                  transition-transform 
                  duration-300
                " 
              />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default InputArea;


type TypeAnimationProps = {
  className?: string;
};

const TypeAnimation: React.FC<TypeAnimationProps> = ({ className = '' }) => {
  return (
    <div 
      className={`
        flex 
        space-x-1 
        justify-center 
        items-center 
        ${className}
      `}
    >
      <div 
        className="
          w-2 
          h-2 
          bg-white 
          rounded-full 
          animate-bounce 
          [animation-delay:-0.3s]
        "
      ></div>
      <div 
        className="
          w-2 
          h-2 
          bg-white 
          rounded-full 
          animate-bounce 
          [animation-delay:-0.15s]
        "
      ></div>
      <div 
        className="
          w-2 
          h-2 
          bg-white 
          rounded-full 
          animate-bounce
        "
      ></div>
    </div>
  );
};