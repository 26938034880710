import Image from "../../Image"
import { FC } from "react";
import InputArea from "./InputArea";
import { 
  Network, 
  Blocks, 
  Coins, 
  Cpu, 
  Shield, 
  Users, 
  Wallet, 
  Database,
  Brain,
  Bot,
  Share2,
  Lock
} from 'lucide-react';

type THeroProps = {
  promptValue: string;
  setPromptValue: React.Dispatch<React.SetStateAction<string>>;
  handleDisplayResult: () => void;
};

const Hero: FC<THeroProps> = ({
  promptValue,
  setPromptValue,
  handleDisplayResult,
}) => {
  const handleClickSuggestion = (value: string) => {
    setPromptValue(value);
  };

  return (
    <div className="flex flex-col items-center justify-center">

<h2 className="pb-7 pt-2 text-center text-4xl font-bold tracking-tight lg:text-[72px]">
      <p 
        className="
          text-2xl
          sm:text-4xl
          md:text-5xl
          lg:text-6xl
          bg-haiku-gradient
          bg-clip-text
          text-transparent
         bg-[length:100%] 
        "
      >
        Empower AI-Powered Insights
      </p>
      <span 
        className="
          text-2xl 
          lg:text-4xl 
          text-gray-600 
          inline-block 
          mt-1
        "
      >
        <i> smarter & faster </i>
      </span>
    </h2>

      {/* input section */}
      <div className="w-full max-w-[708px] pb-6">
        <InputArea
          promptValue={promptValue}
          setPromptValue={setPromptValue}
          handleDisplayResult={handleDisplayResult}
        />
      </div>

      {/* Suggestions section */}
      <div className="flex flex-wrap items-center justify-center gap-2.5 pb-[30px] ">
        {suggestions.map((item) => {
          const IconComponent = item.icon;
         return <div
         onClick={() => handleClickSuggestion(item.name)}
         className="
           relative
           group
           flex 
           h-11
           cursor-pointer 
           items-center 
           gap-2
           rounded-full
           bg-gradient-to-r
           from-gray-50
           to-gray-100
           px-5 
           py-2.5
           transition-all
           duration-300
           hover:from-blue-50
           hover:to-indigo-50
           hover:-translate-y-0.5
           hover:shadow-[0_4px_20px_-3px_rgba(0,0,0,0.1)]
           active:scale-95
           before:absolute
           before:inset-0
           before:rounded-full
           before:border
           before:border-gray-200/80
           before:transition-colors
           hover:before:border-blue-200/80
         "
       >
      <IconComponent className="h-5 w-5 text-blue-500" />
      {/* <Image
           unoptimized
           src={item.icon}
           alt={item.name}
           width={18}
           height={16}
           className="
             relative
             w-[18px] 
             transition-transform 
             duration-300 
             group-hover:rotate-3
             group-hover:scale-110
           "
         /> */}
         <span className="
           relative
           text-sm 
           font-medium 
           text-gray-600
           group-hover:text-blue-600
           transition-colors
           duration-300
         ">
           {item.name}
         </span>
       </div>
})}
      </div>

    </div>
  );
};




const suggestions: SuggestionType[] = [
  {
    id: 1,
    name: "What are the key benefits of decentralized AI?",
    icon: Network,
  },
  {
    id: 2,
    name: "How does blockchain ensure AI model transparency?",
    icon: Blocks,
  },
  {
    id: 3,
    name: "What is the role of tokens in decentralized AI?",
    icon: Coins,
  },
  {
    id: 4,
    name: "How can I contribute computing resources to the network?",
    icon: Cpu,
  },
  // {
  //   id: 5,
  //   name: "What are the privacy features of Decentr.ai?",
  //   icon: Shield,
  // },
  {
    id: 6,
    name: "How does consensus work in distributed AI training?",
    icon: Users,
  },
  {
    id: 7,
    name: "What are the rewards for participating?",
    icon: Wallet,
  },
  {
    id: 8,
    name: "How does decentralized validation improve AI?",
    icon: Database,
  },
  // {
  //   id: 9,
  //   name: "What makes AI models more efficient on Decentr.ai?",
  //   icon: Brain,
  // },
  // {
  //   id: 10,
  //   name: "How to create AI models on the decentralized network?",
  //   icon: Bot,
  // },
  // {
  //   id: 11,
  //   name: "What are the benefits of shared computing power?",
  //   icon: Share2,
  // },
  // {
  //   id: 12,
  //   name: "How does Decentr.ai ensure data security?",
  //   icon: Lock,
  // }
];

// Type definition if needed
type SuggestionType = {
  id: number;
  name: string;
  icon: React.ElementType; // For Lucide icons
};

export default Hero;
