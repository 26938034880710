// "use client";

import Answer from "../components/searchEngine/components/Answer";
import Footer from "../components/searchEngine/components/Footer";
import Header from "../components/searchEngine/components/Header";
import Hero from "../components/searchEngine/components/Hero";
import InputArea from "../components/searchEngine/components/InputArea";
import SimilarTopics from "../components/searchEngine/components/SimilarTopics";
import Sources from "../components/searchEngine/components/Sources";
import Image from '../components/Image'
// import Image from "next/image";
import { useRef, useState, useEffect} from "react";
import { postRequest } from "../api/axios";
import { BookOpen, Link2, Timer } from "lucide-react";
import Share from "../components/searchEngine/components/Share";
// import {
//   createParser,
//   ParsedEvent,
//   ReconnectInterval,
// } from "eventsource-parser";

export default function Home() {
  const [promptValue, setPromptValue] = useState("");
  const [question, setQuestion] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [sources, setSources] = useState<{ name: string; url: string }[]>([]);
  const [answer, setAnswer] = useState("");
  const [similarQuestions, setSimilarQuestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const [generationTime,setGenerationTime] = useState(0)

    const handleBookmark = () => {
      const currentUrl = window.location.href;
      const title = document.title;
      //@ts-ignore
      if (window.sidebar && window.sidebar.addPanel) {
        // Firefox < 23
         //@ts-ignore
        window.sidebar.addPanel(title, currentUrl, '');
      } else if (window.external && 'AddFavorite' in window.external) {
        // Internet Explorer
         //@ts-ignore
        window.external.AddFavorite(currentUrl, title);
      } else if (navigator.userAgent.toLowerCase().includes('chrome')) {
        // Chrome and modern browsers
        alert(
          'To bookmark this page in Chrome, press Ctrl+D (Windows) or Command+D (Mac), then select a folder to save it.'
        );
      } else {
        // Default fallback
        alert(
          'Press Ctrl+D (Windows) or Command+D (Mac) to bookmark this page.'
        );
      }
    };
  

    useEffect(()=>{

      const searchParams = new URLSearchParams(window.location.href.split(`?`)[1]);
      const searchText = searchParams.get('search');
      if(searchText && question.length ==0){
        handleDisplayResult(searchText)
      }

    },[window.location.href])

  const handleDisplayResult = async (newQuestion?: string) => {
    newQuestion = newQuestion || promptValue;

    setShowResult(true);
    setLoading(true);
    setQuestion(newQuestion);
    setPromptValue("");

    // await Promise.all([
    //   handleSourcesAndAnswer(newQuestion),
    //   handleSimilarQuestions(newQuestion),
    // ]);

    const now = Date.now()
    let data = await postRequest(
      `/api/availableModels/Turbo_Seek`,
      { question: newQuestion }
    );
    if (data) {
      setSources([...data.sources]);
      setAnswer(data.answer);
      setSimilarQuestions(data.similarQuestions);
      setGenerationTime((Date.now() - now)/1000)
    } else {
      setSources([]);
      setAnswer("");
      setSimilarQuestions([]);
    }








    // let sourcesResponse = await fetch("/api/getSources", {
    //           method: "POST",
    //           body: JSON.stringify({ question }),
    //         });
    //         let sources = await sourcesResponse.json();

    //         console.log(sources);

    setLoading(false);
  };



  //   async function handleSourcesAndAnswer(question: string) {
  //     let sourcesResponse = await fetch("/api/getSources", {
  //       method: "POST",
  //       body: JSON.stringify({ question }),
  //     });
  //     let sources = await sourcesResponse.json();

  //     setSources(sources);

  //     const response = await fetch("/api/getAnswer", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ question, sources }),
  //     });

  //     if (!response.ok) {
  //       throw new Error(response.statusText);
  //     }

  //     if (response.status === 202) {
  //       const fullAnswer = await response.text();
  //       setAnswer(fullAnswer);
  //       return;
  //     }

  //     // This data is a ReadableStream
  //     const data = response.body;
  //     if (!data) {
  //       return;
  //     }

  //     const onParse = (event: ParsedEvent | ReconnectInterval) => {
  //       if (event.type === "event") {
  //         const data = event.data;
  //         try {
  //           const text = JSON.parse(data).text ?? "";
  //           setAnswer((prev) => prev + text);
  //         } catch (e) {
  //           console.error(e);
  //         }
  //       }
  //     };

  //     // https://web.dev/streams/#the-getreader-and-read-methods
  //     const reader = data.getReader();
  //     const decoder = new TextDecoder();
  //     const parser = createParser(onParse);
  //     let done = false;
  //     while (!done) {
  //       const { value, done: doneReading } = await reader.read();
  //       done = doneReading;
  //       const chunkValue = decoder.decode(value);
  //       parser.feed(chunkValue);
  //     }
  //   }

  //   async function handleSimilarQuestions(question: string) {
  //     let res = await fetch("/api/getSimilarQuestions", {
  //       method: "POST",
  //       body: JSON.stringify({ question }),
  //     });
  //     let questions = await res.json();
  //     setSimilarQuestions(questions);
  //   }

  const reset = () => {
    setShowResult(false);
    setPromptValue("");
    setQuestion("");
    setAnswer("");
    setSources([]);
    setSimilarQuestions([]);
  };

  return (
    <>
      {/* <Header /> */}
      <main className="mt-auto mb-auto px-4 pb-4  ">
        {!showResult && (
          <Hero
            promptValue={promptValue}
            setPromptValue={setPromptValue}
            handleDisplayResult={handleDisplayResult}
          />
        )}

        {showResult && (
          <div className="flex h-full min-h-[68vh] w-full grow flex-col justify-between">
            <div className="pt-1 sm:pt-2" ref={chatContainerRef}></div>
            <div className="container w-full my-4 px-4 lg:px-0">
              <InputArea
                promptValue={promptValue}
                setPromptValue={setPromptValue}
                handleDisplayResult={handleDisplayResult}
                disabled={loading}
                reset={reset}
              />
            </div>
            <div className="container w-full space-y-2">
              <div className="container space-y-2">
                <div className="bg-haiku-gradient rounded-lg p-8 text-white shadow-xl">
                  <h2 className="text-md font-bold uppercase tracking-wider mb-2 opacity-80">Question: </h2>
                  <p className="text-xl font-light">&quot;{question}&quot;</p>
                </div>
                <div className="flex flex-col items-stretch">
                  <Answer answer={answer} />
                  <div className="mt-3 flex flex-col justify-center">

                {(generationTime > 0 && !loading) && <div className="grid grid-cols-2 gap-4">
                  <div className="text-center p-3 bg-blue-50 rounded-lg">
                    <BookOpen className="w-5 h-5 text-blue-500 mx-auto mb-1" />
                    <div className="text-2xl font-semibold text-gray-900">6</div>
                    <div className="text-sm text-gray-500">Sources</div>
                  </div>
                  <div className="text-center p-3 bg-green-50 rounded-lg">
                    <Timer className="w-5 h-5 text-green-500 mx-auto mb-1" />
                    <div className="text-2xl font-semibold text-gray-900">{generationTime}s</div>
                    <div className="text-sm text-gray-500">Generation</div>
                  </div>
                </div>}

                  <Sources sources={sources} />
                  <SimilarTopics
                    similarQuestions={similarQuestions}
                    handleDisplayResult={handleDisplayResult}
                    reset={reset}
                  />
                </div>

                <div className="bg-haiku-gradient text-white p-4 w-full rounded-xl">
                <h3 className="font-semibold mb-3">Quick Actions</h3>
                <div className="space-y-2">
                  {question && <Share title={question} />}
                  <button
                  onClick={handleBookmark}
                    
                  
                  className="w-full p-2 bg-white/10 hover:bg-white/20 rounded-lg transition-colors flex items-center gap-2">
                    <BookOpen className="w-4 h-4" />
                    Save for Later
                  </button>
                </div>
              </div>

                </div>
                  
                
              </div>

            </div>

          </div>
        )
        }
      </main>
      {/* <Footer /> */}
    </>
  );
}


