import React, { ChangeEvent, useEffect, useState } from 'react'
import Sidebar from "../Sidebar";
import {
  useNavigate,
  Outlet,
} from "react-router-dom";


import { RxHamburgerMenu } from "react-icons/rx";
import Image from "../../Image";
import { IoSearch } from "react-icons/io5";
import Signin from '../../Signin';
import Button from '../../buttons';
import { addModels } from '../../../Redux/Slices/models';

import { useLocation } from "react-router-dom";
import { getRequest } from '../../../api/axios';
import { useDispatch, useSelector } from 'react-redux';
import Search from '../../modelSearch'


const DashboardLayout = () => {

  const [activeSidebar, setActiveSidebar] = useState(true);
  const [isBelow1025,setIsBelow1025] = useState(false);

  const [isHome, setIsHome] = useState(false);

  const location = useLocation();
  const { pathname } = location;


  const dispatch = useDispatch()
  const modelsList = useSelector((state:any) => state.models)

  const getAvailableModels = async () => {
    try {
      let response = await getRequest(`/api/availableModels`);
      if(response.data){
        let sortingList = [...response.data];
        let sorted:any = sortingList.sort((a:any,b:any) => a.displayOrder - b.displayOrder);
        dispatch(addModels(sorted))
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    if(!modelsList || modelsList.length <=0){
      getAvailableModels();
    }

  },[])

  const handleSidebar = () => {
    setActiveSidebar(!activeSidebar)
  }

  useEffect(() => {
    if (pathname === `/`  || pathname === `/AllModels` ) {
      setIsHome(true)
    } else {
      setIsHome(false);
    }

  }, [pathname])



  const handleWidth = ()=>{
    if(window.innerWidth >= 1025){
      setIsBelow1025(false);
      setActiveSidebar(true);
    }else{
      setIsBelow1025(true)
      setActiveSidebar(false);
    }
  }

  useEffect(()=>{
    handleWidth()
    if(window){
      window.addEventListener(`resize`,handleWidth)
    }

    return ()=>{
      window.removeEventListener(`resize`,handleWidth)
    }
  },[])




  return (<>
    <main className="flex flex-col w-full relative max-w-[1900px] mx-auto">
      <div className={`absolute top-0 left-0 ${['','#/','#/AllModels'].includes(window.location.hash) ? 'w-full' : 'w-fit' } z-[8] py-4 px-2 sm:px-8 flex items-center justify-start`}>

        <div className=" flex flex-col items-center justify-around md:flex-row">
          <div className='flex items-center justify-start gap-3 min-w-[150px] sm:min-w-[150px] lg:min-w-[240px]'>
            <RxHamburgerMenu onClick={() => handleSidebar()} className="text-[24px] cursor-pointer" />
            <Image src="/decentrLogo.png" style={`w-auto cursor-pointer w-10 h-10`} />
          </div>
          {isHome && <Search mobile={false}/>}
        </div>



        {isHome && <div className='flex gap-2 items-center justify-center ml-auto '>
          <Button design={`primary`}><Signin /></Button>
          <Button design={`secondary`}>Sign up</Button>
        </div>
        }


{isHome && <div className='absolute w-[80%] top-16 left-[50%] translate-x-[-50%]'><Search mobile={true}/></div>}


      </div>
      <div className="flex w-full">
        {activeSidebar && <Sidebar isBelow1025={isBelow1025} activeSidebar={activeSidebar} handleSidebar={handleSidebar} />}
        <section className="bg-white min-h-screen w-full h-full  ml-auto mr-auto flex flex-col gap-20" >
          <Outlet />
        </section>
      </div>
    </main>
  </>
  );
};

export default DashboardLayout;


